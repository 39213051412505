import { ErrorHandler, Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
@Injectable({
  providedIn: 'root'
})
export class ChunkErrorHandlerService implements ErrorHandler{

  bugsnagErrorHandler: BugsnagErrorHandler;

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
  }

  constructor(bugsnagErrorHandler: BugsnagErrorHandler) {
    // console.log("Chunk Service handler called");
    this.bugsnagErrorHandler = bugsnagErrorHandler;
  }

  handleError(error: any): void {
    const chunkFailedMessage = /\bloading\b.*\bchunk\b|\bchunk\b.*\bloading\b/i;
    // console.log("ChunkErrorHandlerService: handle Error: ", error);
    if (chunkFailedMessage.test(error.message)) {
      localStorage.setItem('chunkLoadError', 'true');
      console.log("Chunk load error detected: Notifying: ", error.message);
      Bugsnag.notify(new Error(`succesfully handled chunk load error: ${error.message}`));
      console.log("Chunk load error detected: Reloading app...");
      new Promise(f => setTimeout(f, 3000)).then(() => window.location.reload());
    } else {
      console.error('Non-Chunk error: ', error);
      this.bugsnagErrorHandler.handleError(error);
    }
  }

}
